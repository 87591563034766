/**
 * @file Front Page.
 */

/**
 * Front Page functions.
 *
 * Runs js specific to the Front Page template.
 */
const vFrontPage = async () => {
	import('../../components/hp-promos/hp-promos').then((m) => m.default());
	import('../../components/hp-awards/hp-awards').then((m) => m.default());
	import('../../components/hp-stats/hp-stats').then((m) => m.default());
	import('../../components/hp-featured-news/hp-featured-news').then((m) => m.default());
	import('../../components/hp-intro/hp-intro').then((m) => m.default());
};

if (document.readyState !== 'loading') {
	vFrontPage();
} else {
	document.addEventListener('DOMContentLoaded', vFrontPage);
}
